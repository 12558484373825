import React from "react";
import Link from "next/link";
import { GetIssuesResponseItem } from "@/app/lib/network/issue/GetIssues";
import { styled } from "@mui/material/styles";
import { sendGAEvent } from "@next/third-parties/google";

type Props = {
  children: React.ReactNode;
  issue: GetIssuesResponseItem;
  onClick: () => void;
  href?: string;
  sendEvents?: boolean;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

const StyledIssueLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  display: "flex",
  flexFlow: "column",
  height: "100%",
});

export const IssueLink: React.FunctionComponent<Props> = ({
  children,
  issue,
  onClick,
  href = "",
  sendEvents = true,
  eventCategory,
  eventAction,
  eventLabel,
}) => (
  <StyledIssueLink
    id={`tile-${issue.issueDescription.name}`}
    href={href}
    onClick={() => {
      onClick();
      if (sendEvents) {
        sendGAEvent("event", "buttonClicked", {
          value: `${eventCategory} | ${eventAction} | ${eventLabel}`,
        });
      }
    }}
  >
    {children}
  </StyledIssueLink>
);
