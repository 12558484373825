import { AxiosPromise } from "axios";
import restApi from "@/app/lib/services/restApi";

type GetIssuesProps = {
  status?: "ACTIVE" | "SOON" | "END";
};

export interface GetIssuesResponseItem {
  id: string;
  issueDescription: {
    name: string;
    slug: string;
    shortDescription: string;
    coverPath: string;
    logoPath: string;
  };
  endDate: string;
  startDate: string;
  status: string;
  type: string;
  seriesOfShares: {
    id: string;
    price: number;
    amount: number;
    name: string;
    position: number;
    currentAmount: number;
  }[];
  investorCount: number;
}

export const GetIssues = ({
  status,
}: GetIssuesProps): AxiosPromise<GetIssuesResponseItem[]> =>
  restApi.get(`/api/v1/issues${status ? `?status=${status}` : ""}`);
