import Image from "next/image";
import React from "react";
import { getTimeUntilEnd } from "@/app/lib/utils/dateHelper";
import {
  ClockContent,
  ClockContentDays,
  ClockContentTillEnd,
  ClockProgress,
  ClockRoot,
  Clock as StyledClock,
} from "./Clock.styles";

interface ClockProps {
  progress: number;
  date: Date;
}

const Clock = ({ progress, date }: ClockProps) => {
  const circumference = 2 * Math.PI * 30;
  const percent = progress / 100;
  const start = 1 - percent;
  const end = 1 - start;

  return (
    <ClockRoot>
      <StyledClock>
        <Image
          width="29"
          height="44"
          src="/images/index/hourglass.png"
          alt="klepsydra"
        />
        <ClockProgress
          strokeDasharray={`${circumference * start} ${circumference * end}`}
        />
      </StyledClock>
      <ClockContent>
        <ClockContentDays className="content__additionals--number">
          {getTimeUntilEnd(date)}
        </ClockContentDays>
        <ClockContentTillEnd className="content__additionals--text">
          do końca
        </ClockContentTillEnd>
      </ClockContent>
    </ClockRoot>
  );
};

export default Clock;
