import { stripHtml } from "@/app/lib/helpers/stripHTML";
import { getCollectedMoney } from "@/app/lib/domain/issue/Issue";
import {
  color,
  fontSize,
  fontWeight,
  gradient,
  Breakpoint,
} from "@/app/lib/styles";
import { percentageUntilEnd } from "@/app/lib/utils/dateHelper";
import { numberWithSpaces } from "@/app/lib/utils/numberHelper";
import { route } from "@/app/lib/utils/routes";
import { useRef, useEffect } from "react";
import { IssueLink } from "./IssueLink";
import { Issue } from "./IssuesList.styles";
import { ClockContentDays, ClockContentTillEnd } from "./parts/Clock.styles";
import Clock from "./parts/Clock";
import { GetIssuesResponseItem } from "@/app/lib/network/issue/GetIssues";
import WalletSVG from "@/app/lib/static/svg/index-page/icon_wallet.svg";
import InvestorSVG from "@/app/lib/static/svg/index-page/icon_ppl.svg";
import Image from "next/image";

interface IssueListItem {
  issue: GetIssuesResponseItem;
}

export const IssueListItem = ({ issue }: IssueListItem) => {
  const ref = useRef<HTMLDivElement>(null);
  const description = stripHtml(issue.issueDescription.shortDescription);
  const limitedDescription =
    description.split(" ").length > 28
      ? `${description.split(" ").slice(0, 28).join(" ")}...`
      : description;
  const collectedCount = getCollectedMoney(issue);
  const percentTime = percentageUntilEnd(
    new Date(issue.startDate),
    new Date(issue.endDate),
  );

  const buttonText = () => {
    switch (issue.status.toLowerCase()) {
      case "soon":
        return "Pokaż więcej";
      case "end":
        return "Zobacz szczegóły";
      default:
        return "Zainwestuj";
    }
  };

  useEffect(() => {
    if (
      "undefined" !== typeof window &&
      window.location.hash &&
      `#issue-${issue.issueDescription.slug}` === window.location.hash
    ) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 1000);
    }
  }, [issue.issueDescription.slug]);

  return (
    <Issue key={issue.id}>
      <article
        id={`issue-${issue.issueDescription.slug}`}
        ref={ref}
        className={issue.status.toLowerCase()}
      >
        <IssueLink
          issue={issue}
          onClick={() => {}}
          href={route.issue(issue.issueDescription.slug)}
          eventCategory="Homepage"
          eventAction={`Click ${issue.status}`}
          eventLabel={issue.issueDescription.name}
        >
          <div className="wrapper">
            <Image
              width={389}
              height={169}
              layout="responsive"
              className="wrapper__background"
              src={issue.issueDescription.coverPath}
              alt={`${issue.issueDescription.name} logo`}
            />
          </div>
          <div className="content">
            <div className="content__header">
              <h1>{issue.issueDescription.name}</h1>
              <p>{limitedDescription}</p>
            </div>
            <div className="content__additionals">
              <div className="content__additionals--stats">
                <div className="content__additionals--stats--item">
                  <div>
                    <WalletSVG />
                  </div>
                  <div>
                    <ClockContentDays>
                      {numberWithSpaces(collectedCount)} zł
                    </ClockContentDays>
                    <ClockContentTillEnd>
                      wartość zebranych zapisów na akcje
                    </ClockContentTillEnd>
                  </div>
                </div>
                <div className="content__additionals--stats--item">
                  <div>
                    <InvestorSVG />
                  </div>
                  <div>
                    <ClockContentDays>{issue.investorCount}</ClockContentDays>
                    <ClockContentTillEnd>inwestorów</ClockContentTillEnd>
                  </div>
                </div>
              </div>
              <div>
                {percentTime && (
                  <Clock
                    progress={percentTime}
                    date={new Date(issue.endDate)}
                  />
                )}
              </div>
            </div>
            <div className="content__more-button">
              <span>{buttonText()}</span>
            </div>
          </div>
        </IssueLink>
      </article>

      <style jsx>{`
        .end .content__more-flex:last-child p:last-child {
          color: ${color.failure};
        }

        .soon .wrapper__follow,
        .soon .content__additionals,
        .soon .content__progressbar,
        .soon .content__more {
          display: none;
        }

        .content__more-button {
          font-size: ${fontSize.default};
          font-weight: ${fontWeight.bold};
          text-align: center;
          color: ${color.primary};
          margin: 26px auto 0 auto;
          background: transparent;
          border-radius: 24px;
          border: double 2px transparent;
          background-image: ${`linear-gradient(white, white),
              ${gradient.primaryCircle}`};
          background-origin: border-box;
          background-clip: content-box, border-box;
        }
        .content__more-button span {
          display: block;
          padding: 13px 45px;
        }
        @media screen and (min-width: ${Breakpoint.MOBILE}) {
          .content__more-button {
            margin-bottom: 30px;
          }
          .content__more-button span {
            padding: 13px 76px;
          }
        }

        .content__more {
          min-height: 36px;
          display: flex;
          justify-content: space-between;
        }
        .content__more p {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: ${color.black};
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.regular};
        }
        .content__more-flex p:first-child {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .content__more-flex p:last-child {
          padding-top: 3px;
        }

        .content__more p:last-child {
          margin: 0 auto;
          color: ${color.black};
          font-size: ${fontSize.default};
          font-weight: ${fontWeight.bold};
        }

        .content {
          padding: 21px;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        }
        .content__header {
          height: 100%;
          display: flex;
          padding-bottom: 14px;
          justify-content: space-evenly;
          flex-direction: column;
        }
        .content__header h1 {
          color: ${color.black};
          padding: 14px 0;
          font-size: ${fontSize.subtitle};
          font-weight: ${fontWeight.bold};
        }
        .content__header p {
          font-family: Montserrat;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.444px;
          color: ${color.lightGrey};
          font-size: ${fontSize.default};
        }

        @media screen and (min-width: ${Breakpoint.MOBILE}) {
          .content {
            padding: 32px;
          }
          .content__header h1 {
            padding: 18px 0;
          }
          .content__header {
            padding-bottom: 24px;
          }
        }

        .content__additionals {
          display: flex;
          gap: 40px;
        }

        .content__additionals--stats {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        .content__additionals--stats--item {
          display: flex;
          gap: 16px;
          align-items: center;
        }

        .container {
          margin-bottom: 40px;
        }
        .wrapper {
          position: relative;
          z-index: 2;
        }

        .wrapper__follow {
          position: absolute;
          right: 32px;
          bottom: -2.571em;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.571em;
          height: 2.571em;
          border-radius: 0 0 24px 24px;
          background-color: ${color.lightPink};
        }
        article {
          height: 100%;
          max-width: 318px;
          width: 100%;
          /* max-height: 625px; */
          margin: 0 auto;
          overflow: hidden;
          box-shadow: 0 0 32px rgba(45, 11, 143, 0.07);
          border-radius: 24px;
          background-color: #ffffff;
        }
        @media screen and (min-width: ${Breakpoint.TABLET}) {
          article {
            max-width: 368px;
            /* max-height: 650px; */
          }
        }
        .wrapper__background {
          width: auto;
          display: block;
          height: 151px;
          margin-left: 50%;
          transform: translateX(-50%);
          object-fit: cover;
        }
        @media screen and (min-width: ${Breakpoint.MOBILE}) {
          .wrapper__background {
            height: 169px;
          }
        }
      `}</style>
      <style jsx global>{`
        .end .progress__container {
          display: none;
        }

        .end .content__additionals {
          gap: 0;
        }

        .soon .content__progressbar {
          display: none;
        }
        .wrapper__follow svg {
          width: 14px;
          height: 14px;
          fill: none;
        }
        .wrapper__follow-active svg {
          fill: #4200ff;
        }
      `}</style>
    </Issue>
  );
};
