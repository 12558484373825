import { styled } from "@mui/material/styles";
import { color, fontSize } from "@/app/lib/styles";

export const ClockRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-bettwen",
});

export const Clock = styled("div")({
  postion: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    position: "absolute",
    maxWidth: "22px",
  },
});

export const ClockContent = styled("div")({
  marginTop: "12px",
  textAlign: "center",
});

export const ClockContentDays = styled("p")({
  fontSize: `${fontSize.bigger}`,
  fontWeight: "800",
  lineHeight: "24px",
  letterSpacing: "0.5px",
});

export const ClockContentTillEnd = styled("p")({
  fontFamily: "Montserrat",
  fontWeight: "400",
  lineHeight: "18px",
  letterSpacing: "0.389px",
  color: `${color.lightGrey}`,
  fontSize: `${fontSize.small}`,
});

export const ClockSVG = styled("svg")({
  width: 70,
  height: 70,
});

export const ClockCirle1 = styled("circle")({
  stroke: "#ccc",
  strokeWidth: "2",
  strokeDasharray: "2",
  fill: "none",
  r: "30",
  cx: "35",
  cy: "35",
});

export const ClockCirle2 = styled("circle")(({ strokeDasharray }) => ({
  stroke: "#4ecca3",
  strokeWidth: "2",
  strokeDasharray: strokeDasharray,
  strokeDashoffset: "0",
  strokeLinecap: "round",
  transform: "rotate(-90 0 0)",
  transformOrigin: "50% 50%",
  fill: "none",
  r: "30",
  cx: "35",
  cy: "35",
}));

export const ClockProgress = ({
  strokeDasharray,
}: {
  strokeDasharray: string;
}) => (
  <ClockSVG>
    <ClockCirle1 />
    <ClockCirle2 strokeDasharray={strokeDasharray} />
  </ClockSVG>
);
