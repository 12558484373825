import { GetIssueResponse } from "@/app/lib/network/issue/GetIssue";
import { GetIssuesResponseItem } from "@/app/lib/network/issue/GetIssues";
import { GetOrdersResponseItem } from "@/app/lib/network/order/GetOrders";
// TODO: Refactor
// In future should be moved to Issue domain class that would have proper method for calculation
export const getCollectedMoney = (
  issue: GetIssueResponse | GetIssuesResponseItem,
): number => {
  return (
    issue.seriesOfShares.reduce<number>(
      (aggregator: number, seriesOfShares) => {
        let amount = seriesOfShares.amount - seriesOfShares.currentAmount;

        if (amount < 0) {
          amount = seriesOfShares.amount + Math.abs(amount);
        }

        return aggregator + amount * seriesOfShares.price;
      },
      0,
    ) / 100
  );
};

// TODO: Refactor
// In future should be moved to Issue domain class that would have proper method for calculation
export const getGoalMoney = (
  issue: GetIssueResponse | GetIssuesResponseItem,
): number => {
  return (
    issue.seriesOfShares.reduce<number>(
      (aggregator: number, seriesOfShares) => {
        return aggregator + seriesOfShares.amount * seriesOfShares.price;
      },
      0,
    ) / 100
  );
};

// TODO: Refactor
// In future should be moved to Issue domain class that would have proper method for calculation
export const getInvestmentAmount = (order: GetOrdersResponseItem): number => {
  return (
    order.shares.reduce<number>((aggregator: number, orderShares) => {
      return aggregator + orderShares.amount * orderShares.seriesOfShares.price;
    }, 0) / 100
  );
};
