import { Grid2, useTheme } from "@mui/material";
import React from "react";

export const Issue = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Grid2
      size={{ lg: 4 }}
      sx={{
        marginTop: "0px",
        [theme.breakpoints.up("xl")]: {
          "&:nth-of-type(3n - 1)": {
            marginTop: "48px",
          },
          "&:nth-of-type(3n - 2)": {
            marginTop: "92px",
          },
          "&:nth-of-type(3n)": {
            marginTop: "0px",
          },
          "&:only-of-type": {
            marginTop: "48px",
          },
        },
      }}
      columnSpacing={{ xs: 12, sm: 6, lg: 4 }}
    >
      {children}
    </Grid2>
  );
};
export const Issues = ({ children }: { children: React.ReactNode }) => (
  <Grid2 container justifyContent={"center"} spacing={6}>
    {children}
  </Grid2>
);
