import React from "react";
import { GetIssuesResponseItem } from "@/app/lib/network/issue/GetIssues";
import { Issues } from "./IssuesList.styles";
import { IssueListItem } from "./IssuesListItem";

interface IssuesListProps {
  issues: GetIssuesResponseItem[];
}

export const IssuesList = ({ issues }: IssuesListProps) => {
  return (
    <Issues>
      {issues.map((issue) => (
        <IssueListItem key={issue.id} issue={issue} />
      ))}
    </Issues>
  );
};
